import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { useTrail, animated } from 'react-spring';

import Glitch from './Glitch';
import LazyLogoCanvas from './Canvas/LogoCanvas/LazyLogoCanvas';
import LazyStarsCanvas from './Canvas/StarsCanvas/LazyStarsCanvas';
import TextGradient from './TextGradient';
import theme from '../theme';

const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    background: `linear-gradient(transparent, ${palette.common.black})`,

    [breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
}));

const items = [
  <Typography variant="h4" component="span" style={{ lineHeight: 3 }}>
    <Glitch delay={1}>Hi</Glitch>, I am
  </Typography>,
  <Typography variant="h1" component="span" style={{ lineHeight: 0.5 }}>
    <TextGradient>webman,</TextGradient> <br />{' '}
    <span style={{ color: theme.palette.primary.main }}>
      <TextGradient>a front</TextGradient>
      <Glitch delay={3}>end</Glitch>
    </span>
    <br /> <TextGradient>addict.</TextGradient>
  </Typography>,
];

const Hero = () => {
  const classes = useStyles();
  const trail = useTrail(items.length, {
    config: { mass: 8, tension: 3000, friction: 200 },
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: 50 },
  });

  return (
    <div className={classes.root}>
      <Container>
        <Grid component="section" item xs={12} md={8}>
          <h1>
            {trail.map(({ x, ...rest }, index) => (
              <animated.span
                key={index}
                style={{
                  ...rest,
                  transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
                  display: 'block',
                }}
              >
                {items[index]}
              </animated.span>
            ))}
          </h1>
        </Grid>
      </Container>
      <LazyLogoCanvas />
      <LazyStarsCanvas />
    </div>
  );
};

export default Hero;
