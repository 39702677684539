import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import { mergeClasses } from '../utils';

const useStyles = makeStyles<Theme>(({ palette }) => ({
  root: {
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    background: `-webkit-linear-gradient(-45deg, ${palette.secondary.main} 25%, ${palette.primary.main} 60%)`,
    backgroundColor: palette.secondary.main,
  },
}));

interface TextGradientProps {
  className?: string;
  component?(props: object): React.ReactElement;
}

const TextGradient: React.FC<TextGradientProps> = ({
  children,
  className,
  component,
}) => {
  const classes = useStyles();
  const classNames = mergeClasses(className, classes.root);

  if (component) {
    return component({ children, className: classNames });
  }

  return <span className={classNames}>{children}</span>;
};

export default TextGradient;
