import React from 'react';
import { graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import type { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import Email from '../components/Email';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Schema from '../components/Schema';
import Seo from '../components/Seo';
import TextGradient from '../components/TextGradient';
import type { GatsbyPageProps } from '../types';
import { mergeClasses } from '../utils';

import react from '../images/technologies/react.svg';
import nextjs from '../images/technologies/nextjs.svg';
import gatsby from '../images/technologies/gatsby.svg';
import js from '../images/technologies/js.svg';
import nodejs from '../images/technologies/nodejs.svg';
import typescript from '../images/technologies/typescript.svg';
import jquery from '../images/technologies/jquery.svg';
import jest from '../images/technologies/jest.svg';
import html from '../images/technologies/html.svg';
import css from '../images/technologies/css.svg';
import graphqlSVG from '../images/technologies/graphql.svg';
import wordpress from '../images/technologies/wordpress.svg';
import Glitch from '../components/Glitch';

const technologiesImgMap = [
  react,
  nextjs,
  gatsby,
  js,
  nodejs,
  typescript,
  jest,
  jquery,
  html,
  css,
  graphqlSVG,
  wordpress,
];

const yearOfCareerBegun = 2013;

const useStyles = makeStyles<Theme>(
  ({ breakpoints, spacing, palette, transitions }) => ({
    section: {
      maxWidth: '100%',
      padding: spacing(8, 0),
      position: 'relative',
      width: '100%',
      background: palette.common.black,

      [breakpoints.up('md')]: {
        padding: spacing(24, 0),
      },
    },
    sectionLight: {
      background: palette.common.white,
      color: palette.common.black,
    },
    sectionTransparent: {
      background: 'transparent',
    },
    topBlueGradient: {
      background: `linear-gradient(${palette.secondary.dark}, transparent 95%)`,
    },
    labelAbout: {
      top: '50%',
      right: spacing(4),
      position: 'absolute',
      zIndex: 1,

      [breakpoints.up('md')]: {
        right: spacing(1),
      },

      '& span': {
        display: 'block',
        transform: 'translate(50%, -50%) rotate(-90deg)',
      },
    },
    technologyLogo: {
      display: 'block',
      filter: 'grayscale(1) contrast(0.7)',
      height: spacing(5),
      margin: `${spacing(5)}px auto`,
      maxWidth: '100%',
      transition: `filter ${transitions.duration.shortest}ms`,

      '&:hover': {
        filter: 'none',
      },
    },
    abstraction: {
      top: 0,
      bottom: 0,
      height: 'auto',
      left: 0,
      margin: 'auto',
      maxWidth: 400,
      pointerEvents: 'none',
      right: 0,
      width: '100%',
      stroke: palette.secondary.dark,
      display: 'block',
    },
  })
);

type Data = any;

const Index: React.FC<GatsbyPageProps<Data>> = ({ data }) => {
  const { site, logoPNG } = data;
  const {
    authorLinks,
    description,
    email,
    siteName,
    siteUrl,
    title,
    author,
  } = site.siteMetadata;
  const sameAs = authorLinks?.reduce((acc, link) => [...acc, link.href], []);
  const classes = useStyles();

  return (
    <>
      <Seo description={description} ogType="website" path="/" title={title} />
      <Schema
        organization={{
          email,
          logoCaption: siteName,
          logoUrl: `${siteUrl}/logo.png`,
          name: siteName,
          sameAs,
          siteUrl,
        }}
        person={{
          description,
          imageUrl: ``,
          name: author,
          sameAs,
        }}
      />
      <Hero />
      <section className={classes.section}>
        <Container>
          <Box position="relative">
            <Typography variant="h4" gutterBottom>
              <TextGradient>About.</TextGradient>
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Typography variant="h6" gutterBottom>
                  Since {yearOfCareerBegun}, I have been working for and with
                  businesses that dare to imagine and create the future on their
                  own. In my free time, I try to be useful to the web
                  development community.
                </Typography>
                <Typography>
                  Occasionally, I share my experience and thoughts about
                  frontend development on my blog.
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="end"
                  width="100%"
                  height="100%"
                >
                  <Typography variant="h6" gutterBottom>
                    Proud to be{' '}
                    <img
                      alt="trident"
                      src="/images/ukraine/trident.svg"
                      style={{ width: '12px' }}
                    />{' '}
                    <TextGradient>Ukrainian</TextGradient>.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </section>
      <section className={mergeClasses(classes.section, classes.sectionLight)}>
        <Container>
          <Box mb={6}>
            <Typography variant="h2" gutterBottom>
              Experience.
            </Typography>
            <Typography>
              For {new Date().getFullYear() - yearOfCareerBegun} years, I have
              acquired valuable experience
            </Typography>
            <Typography>
              Today, I create world-class digital products using first-class
              technologies.
            </Typography>
          </Box>
          <Grid container>
            {technologiesImgMap.map((base64, i) => (
              <Grid item xs={3} key={i}>
                <img
                  alt="Logo technology"
                  className={classes.technologyLogo}
                  src={base64}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </section>
      <section
        className={mergeClasses(classes.section, classes.topBlueGradient)}
      >
        <Container>
          <Box mb={10} textAlign="center">
            <Typography variant="h2" component="p">
              I can help you make your business stable and recognizable through
              a solid <Glitch>web software</Glitch>.
            </Typography>
          </Box>
        </Container>
      </section>
      <section
        className={mergeClasses(classes.section, classes.sectionTransparent)}
      >
        <Container>
          <Box textAlign="center" mb={10}>
            <Typography variant="h2" component="p">
              <TextGradient>Let's talk!</TextGradient>
            </Typography>
            <Typography variant="h6" gutterBottom>
              I believe we can do an incredible things together.
            </Typography>
            <Email email={email} variant="h5" />
          </Box>
        </Container>
      </section>
      <Footer email={email} authorLinks={authorLinks} position={'static'} />
    </>
  );
};

export default Index;

export const pageQuery = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
        description
        author
        authorLinks {
          href
          label
        }
        email
        siteName
        siteUrl
      }
    }
  }
`;
